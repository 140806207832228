@import '../../../styles/basics';

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;

  .bar {
    background: $pink-800;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
  }
}
